<script setup>
import { i18n } from '@/i18n';
import { ValidationObserver } from 'vee-validate';
import { product, setToDefaultValue } from './state';
import { onUnmounted, ref, watch, watchEffect } from 'vue';
import SectionDetail from './SectionDetail/index.vue';
import SectionGallery from './SectionGallery/index.vue';
import SectionInventory from './SectionInventory/index.vue';
import SectionShipping from './SectionShipping/index.vue';
import SectionTypeAndPrice from './SectionTypeAndPrice/index.vue';
import { useCategory } from '../../composables/category';
import { useRoute } from 'vue-router/composables';

const props = defineProps({
  value: {
    required: true,
  },
  editForm: {
    type: Boolean,
    default: false,
  },
})

const SECTIONS = {
  detail: i18n.t('product.product_details'),
  gallery: i18n.t('product.photo_video'),
  price: i18n.t('product.product_type_and_price'),
  shipping: i18n.t('product.weight_product_shipping'),
  inventory: i18n.t('product.inventory'),
}

const route = useRoute()
const { findTreeById, flat: categories, masterCategoryTree, findMasterTreeByChannelCategoryId } = useCategory(route.query.sales_channel_id, route.params.channelId)

watch(() => [props.value, categories.value, masterCategoryTree.value], () => {
  if (props.value) {
    /** @type {import('./type').Product>} */
    const value = { ...props.value }

    value.details.category_tree = findTreeById.value(Number(value.details.category_id))
    value.details.master_category_tree = findMasterTreeByChannelCategoryId.value(value.details.category_id)
    value.catalogs.map(catalog => {
      const price = value.price_list.find(price => price.catalog_id === catalog.id)

      catalog.price = price || {}
      catalog.price.price_msrp = Number(catalog.price.price_msrp || 0)
      catalog.price.price_sales = Number(catalog.price.price_sales || 0)

      catalog.promotions = value.promotions.find(promo => promo.catalog_id === catalog.id)?.discount_list || []
      catalog.commissions = {}
      return catalog
    })

    product.value = value
  }
})

const validationObserver = ref()

onUnmounted(() => {
  setToDefaultValue()
})
</script>

<template>
  <div>
    <a-row
      type="flex"
      :gutter="16"
    >
      <a-col :xs="{ span: 24 }" :lg="{ span: 5 }">
        <ol
          v-scroll-spy-link
          v-scroll-spy-active="{ class: 'active' }"
          class="position-sticky p-0 m-0 mt-4"
        >
          <li
            v-for="(label, key) in SECTIONS"
            :key="key"
          >
            <a
              class=""
              @click.prevent
            >{{ label }}</a>
          </li>
        </ol>
      </a-col>

      <a-col :xs="{ span: 24 }" :lg="{ span: 19 }">
        <ValidationObserver ref="validationObserver" slim>
          <a-form novalidate @submit.prevent="saveProducts">
            <fieldset
              v-scroll-spy="{ offset: 125 }"
              class="main"
            >
              <SectionDetail :edit-form="$props.editForm" />

              <SectionGallery :edit-form="$props.editForm" />

              <SectionTypeAndPrice id="price" :edit-form="$props.editForm" class="mb-5" />

              <SectionShipping id="shipping" :edit-form="$props.editForm" class="mb-5" />
              <SectionInventory id="inventory" :edit-form="$props.editForm" />
            </fieldset>
          </a-form>
        </ValidationObserver>
      </a-col>
    </a-row>
  </div>
</template>

<style lang="scss">
ol {
  list-style: none;
  top: 130px;

  > li {
    position: relative;
    padding: .5rem 2rem;

    a {
      color: #999;
    }

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 20rem;
      background: #999;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      content: '';
      width: 1px;
      background: #999;
      top: 50%;
      left: 4.5px;
      transform: translateY(-50%);
      height: 100%;
      z-index: -1;
    }

    &:first-child::after {
      height: 50%;
      transform: none;
    }

    &:last-child::after {
      height: 50%;
      transform: none;
      top: 0;
    }

    &.active {
      a {
        color: var(--color-black);
        font-weight: 500;
      }

      &::before {
        background: var(--color-accent);
      }
    }
  }
}
</style>
